@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.heading {
  composes: p from global;
  font-weight: var(--fontWeightBold);
  margin: 0 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 0 0;
    padding-top: 5px;
    padding-bottom: 3px;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.fieldFullWidth {
  width: 100%;
  margin-top: 24px;
}
