@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
}

.email {
  margin-top: 37px;

  @media (--viewportMedium) {
    margin-top: 38px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

/* Helper links */
.modalHelperLink {
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}
