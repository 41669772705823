@import '../../../styles/customMediaQueries.css';

.coverEverything {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  transition: var(--transitionStyleButton);
  border-radius: var(--borderRadiusBig);
  background-color: #fff;
  color: var(--colorGrey700);
  
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    /* transform: scale(1.02); */
    box-shadow: var(--boxShadowListingCard);
  }
}

.menuOverlayWrapper {
  /* Positioning */
  composes: coverEverything;
}

.menuOverlay {
  /* Positioning */
  composes: coverEverything;

  /* Overlay */
  background-color: var(--colorGrey300);
  mix-blend-mode: overlay;
  opacity: 0;
  transition: var(--transitionStyleButton);
}

.menuOverlayOpen {
  opacity: 0.4;
}

.clickWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  cursor: pointer;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  composes: coverEverything;
  width: 100%;
  border: solid 1px var(--colorGrey100);
  border-radius: var(--borderRadiusBig) var(--borderRadiusBig) 0 0;
}

.menubarWrapper {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
}

.menubarGradient {
  /* Position */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  border-top-left-radius: var(--borderRadiusBig);
  border-top-right-radius: var(--borderRadiusBig);

  /* Gradient */
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAqCAYAAACUV/o4AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAALEwAACxMBAJqcGAAAAVlpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6dGlmZj0iaHR0cDovL25zLmFkb2JlLmNvbS90aWZmLzEuMC8iPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KTMInWQAAAGhJREFUKBVjZGBgKANibiAGga8sQAKEf4N4QMAC4vwH4r8gHgiABOAcmMAfEAMGsKrA0DIqAAsvHGFKkwACRRQjzGJQRP2DcUA0LOZAEQ5S9R8mAJIEA6wCyIYSqYVSz4FcxwR1E5gCAD7HLabS/HFUAAAAAElFTkSuQmCC');
  background-repeat: repeat;
  opacity: 0.5;
}

.menubar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.menuLabel {
  padding: 0px 9px 0 8px;
}

.iconWrapper {
  padding: 0px 10px 1px 10px;
  color: var(--colorWhite);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.listingMenuIsOpen {
  & .iconWrapper {
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--colorWhite);
  }
}

.menuContent {
  position: absolute;
  right: 0;
  z-index: var(--zIndexPopup);

  background-color: var(--colorBlack);
  border-radius: var(--borderRadiusMedium);
  box-shadow: var(--boxShadowPopup);
}

.menuItem {
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  color: var(--colorWhite);
  white-space: nowrap;
  text-align: left;
  padding: 8px 14px;
  border-radius: var(--borderRadiusMedium);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    background-color: var(--colorFail);
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.menuItemDisabled {
  color: var(--colorGrey100);

  &:hover {
    color: var(--colorGrey100);
  }
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px;
  border-radius: 0 0 var(--borderRadiusBig) var(--borderRadiusBig);
}

.priceAndReviewInfo {

  @media (--viewport1280) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-bottom: 4px;
}

.priceValue {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 18px;
  line-height: 1.1;
  color: var(--marketplaceColor);
  
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  /* Font */
  composes: textSmall from global;
  font-weight: var(--fontWeightSemiBold);

  display: inline-flex;
  align-items: center;
  margin-left: 2px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  color: var(--colorBlack);
}

.noPrice {
  composes: perUnit;
  padding: 5px 0 3px 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.titleWrapper {
  line-height: 24px;
}

.title {
  /* Font */
  font-size: 18px;
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack);
  margin: 0 0 12px;
  border: none;
  padding: 0;
  text-align: left;
}

.titleDraft {
  margin-right: 8px;
}

/* Solid gray background for draft listings without image */
.draftNoImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--colorGrey700);
  border-radius: var(--borderRadiusBig) var(--borderRadiusBig) 0 0;
}

.edit {
  composes: buttonSecondary from global;

  /* TODO: Odd font-size */
  font-size: 14px;

  /* Reset min-height from button styles */
  min-height: 0;

  /* Reserve space for button */
  flex-shrink: 0;
  width: auto;
  height: 41px;
  padding: 7px 14px 7px 35px;

  /* Add edit icon as a background image */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px center;
}

.openListingButton,
.finishListingDraftLink {
  composes: buttonPrimaryInline from global;

  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}

.menu {
  display: none;
}

.cardIsOpen {
  display: block;
}

.manageLinks {
  composes: marketplaceTinyFontStyles from global;
  line-height: 18px;

  /* Remove default margins from font */
  margin-top: 12px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.manageLink {
  text-decoration: underline;
  color: var(--colorBlack);
  white-space: pre;

  &:hover {
    text-decoration: underline;
    color: var(--colorBlack);
  }
}

.manageLinksSeparator {
  margin: 0 3px 0 3px;
}

.closeListingText {
  composes: h5 from global;
}

.category {
  font-size: 14px;
  color: var(--colorGrey700);
  padding: 0;
  margin: 0 0 12px;
}

.description {
  padding: 0;
  margin: 0 0 12px;

  display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;

  font-size: 14px;
  word-break: break-word;
}

.reviewHolder {
  display: flex;
  align-items: center;
  font-family: system-ui;

  & svg {
    margin-top: -2px;
  }
}

.reviewRating {
  margin-right: 5px;
  font-weight: 700;
  font-size: 14px;
}
